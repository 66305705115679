import { createElement } from "react";
import {Grid, Link} from "@material-ui/core";
import {messages} from "../i18n";
import {makeStyles} from "@material-ui/core/styles";
import {theme} from "../theme";


const useStyles = makeStyles({
    footerBar: {
        marginTop: theme.spacing(6),
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2)
    },
    footerLink: {
        color: theme.palette.primary.contrastText
    }
});

export const FooterBar = () => {
    const classes = useStyles();
    return (
        <div className={classes.footerBar}>
            <Grid container spacing={1}>
                <Grid item style={{flexGrow: 1}}>
                </Grid>
                <Grid item>
                    <Link href="/api/docs" underline="hover" className={classes.footerLink}>{messages.footerBar.api}</Link>
                </Grid>
                <Grid item>
                    <Link href="https://www.variocube.com/kontakt/" underline="hover" className={classes.footerLink}>{messages.imprint}</Link>
                </Grid>
            </Grid>
        </div>
    )
}