import {createElement, useEffect, useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {CssBaseline, MuiThemeProvider} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import {theme} from "./theme";
import {
    AuthCallbackProcessingPath,
    AuthCallbackRoute,
    AuthLogin,
    AuthLoginPath,
    AuthLogout,
    AuthLogoutPath, authStorage
} from "./Auth";
import {NotFound} from "./components/NotFound";
import {NavBar} from "./components/NavBar";
import {FooterBar} from "./components/FooterBar";
import { AppContainer } from '@variocube/app-ui';
import {DeliveryCreate, DeliveryDetails, DeliveryList} from "./pages/deliveries";
import {userStore} from "./store/UserStore";
import {UserDetails, UserList} from "./pages/users";
import {CubeDetails, CubeList} from "./pages/cubes";

export const App = () => {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        if (authStorage.authenticated) {
            userStore.load()
                .then(() => setAuthenticated(true));
        }
    }, []);

    return (
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <CssBaseline />
                <BrowserRouter>
                    {authenticated && <NavBar/>}

                    <AppContainer>
                        <Switch>
                            <Route exact path={AuthLoginPath} component={AuthLogin} />
                            <Route exact path={AuthLogoutPath} component={AuthLogout} />
                            <Route exact path={AuthCallbackProcessingPath} component={AuthCallbackRoute} />
                        </Switch>

                        <Switch>
                            <Redirect exact from="/" to={authStorage.authenticated ? '/deliveries' : AuthLoginPath} />

                            <Route exact path="/deliveries" component={DeliveryList} />
                            <Route exact path="/deliveries/create" component={DeliveryCreate} />
                            <Route exact path="/deliveries/:id" component={DeliveryDetails} />

                            <Route exact path="/cubes" component={CubeList} />
                            <Route exact path="/cubes/:id" component={CubeDetails} />

                            <Route exact path="/users" component={UserList} />
                            <Route exact path="/users/:id" component={UserDetails} />

                            {/*<Route exact path="/carrier-tokens" component={CarrierTokensList} />*/}

                            <Route component={NotFound} />
                        </Switch>
                    </AppContainer>

                    {authenticated && <FooterBar />}
                </BrowserRouter>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
};
