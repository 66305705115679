import {createElement} from "react";
import {makeStyles} from "@material-ui/core";

interface SignatureComponentProps {
    signature: string;
}

export function SignatureComponent(props: SignatureComponentProps) {
    const classes = useStyles();
    const signature = props.signature.startsWith('data:image/') ? props.signature : `data:image/png;base64,${props.signature}`;
    return (
        <img className={classes.signature} src={signature} />
    );
}

const useStyles = makeStyles({
    signature: {
        display: 'inline-block',
        maxWidth: '100%'
    }
});
