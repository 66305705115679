import {createElement, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {RouteComponentProps} from "react-router";
import {ColumnType, ContentTable, Page} from "@variocube/app-ui";
import {ApiError} from "../../Api";
import {Box, Breadcrumbs, Grid, Paper, TableCell, TableRow, Typography} from "@material-ui/core";
import { Crumb } from "../../components/Crumb";
import {messages} from "../../i18n";
import {gs} from "../../theme";
import {listDeliveries} from "../../data/deliveries";
import {NotesIcon} from "../../icons";
import {DateComponent} from "../../components/DateComponent";
import {Delivery} from "../../data/types";
import {deliveriesPaging} from "../../data/pagings";
import {DeliveryStateComponent} from "./DeliveryStateComponent";
import {AddFab} from "../../components/AddFab";

const baseColumns = {
    'state': { show: true, name: messages.common.state },
    'service': { show: true, name: messages.deliveries.service },
    'cubeId': { show: true, name: messages.deliveries.cubeBox },
    'reservationId': { show: true, name: messages.deliveries.singular },
    'createdAt': { show: true, name: messages.common.created },
    'storedAt': { show: true, name: messages.deliveries.stored },
    'pickedUpAt': { show: true, name: messages.deliveries.pickedUp },
}

export function DeliveryList({history}: RouteComponentProps) {
    const [page, setPage] = useState<Page<Delivery>>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<ApiError>();
    const [columns, setColumns] = useState<ColumnType>(baseColumns);

    const load = useCallback(async (needle?: string) => {
        setLoading(true);
        try {
            const page = await listDeliveries(deliveriesPaging, needle);
            setPage(page);
        } catch (error) {
            setError(error);
        }
        setLoading(false);

    }, [setPage, setLoading, setError]);

    useEffect(() => {
        load().then();
    }, [])

    const showCell = (column: keyof typeof columns) => columns[column] && columns[column].show;

    return (
        <Fragment>
            <Box my={1}>
                <Breadcrumbs>
                    <Crumb label={messages.deliveries.plural} />
                </Breadcrumbs>
            </Box>
            <Grid container spacing={gs} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{flexGrow: 1}}>
                    <Typography variant="h4" gutterBottom>{messages.deliveries.plural}</Typography>
                </Grid>
                <Grid item>
                {/*    TODO: TABLE SEACH INPUT */}
                </Grid>
            </Grid>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <Paper>
                        {page && (
                            <ContentTable page={page} paging={deliveriesPaging}
                                          inProgress={loading}
                                          columns={columns}
                                          onPagingChange={() => load()}
                                          onColumnsChange={setColumns}
                                          renderTableBody={(
                                              <Fragment>
                                                  {page.content.map(d => (
                                                      <TableRow key={d.id} onClick={() => history.push(`/deliveries/${d.id}`)}>
                                                          {showCell('state') && (
                                                              <TableCell>
                                                                  <DeliveryStateComponent state={d.state} size="small" icon={d.notes ? <NotesIcon /> : undefined} />
                                                              </TableCell>
                                                          )}
                                                          {showCell('service') && <TableCell>{messages.deliveries.services[d.service]}</TableCell>}
                                                          {showCell('cubeId') && <TableCell>{d.cubeId}</TableCell>}
                                                          {showCell('reservationId') && <TableCell>{d.reservationId}</TableCell>}
                                                          {showCell('createdAt') && <TableCell><DateComponent date={d.createdAt} /></TableCell>}
                                                          {showCell('storedAt') && <TableCell>{d.storedAt && <DateComponent date={d.createdAt} />}</TableCell>}
                                                          {showCell('pickedUpAt') && (
                                                              <TableCell>
                                                                  {d.pickedUpAt && <DateComponent date={d.pickedUpAt} />}
                                                                  {d.retrievedAt && <DateComponent date={d.retrievedAt} />}
                                                              </TableCell>
                                                          )}
                                                      </TableRow>
                                                  ))}
                                              </Fragment>
                                          )}
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
            <AddFab onClick={() => history.push('/deliveries/create')} />
        </Fragment>
    )
}