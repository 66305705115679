import red from '@material-ui/core/colors/red';
import { createTheme } from '@material-ui/core/styles';

export const gs = 3;

// A custom theme for this app
export const theme = createTheme({
  palette: {
    primary: {
      main: '#05164d',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#0a2b95',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f5f5f5',
    },
  }
});