import React, {createElement, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {RouteComponentProps} from "react-router";
import {
    Box,
    Breadcrumbs, Button, Dialog, DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    Grid, InputLabel, MenuItem,
    Paper, Select,
    TableCell,
    TableRow, TextField,
    Typography
} from "@material-ui/core";
import {Crumb} from "../../components/Crumb";
import {messages} from "../../i18n";
import {gs} from "../../theme";
import {ColumnType, ContentTable, Page, Selector, TextInput} from "@variocube/app-ui";
import {User, UserCreateRequest, UserRole} from "../../data/types";
import {ApiError} from "../../Api";
import {usersPaging} from "../../data/pagings";
import {createUser, listUsers} from "../../data/users";
import {UserRoleDisplayComponent} from "./UserRoleDisplayComponent";
import {AddFab} from "../../components/AddFab";

const baseColumns: ColumnType = {
    'role': { show: true, name: messages.users.role },
    'email': { show: true, name: messages.common.email }
}

export const UserList = ({history}: RouteComponentProps) => {
    const [page, setPage] = useState<Page<User>>();
    const [form, setForm] = useState<UserCreateRequest>({
        email: '',
        role: UserRole.User
    });
    const [createMode, setCreateMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<ApiError>();
    const [columns, setColumns] = useState<ColumnType>(baseColumns);

    const load = useCallback(async () => {
        setLoading(true);
        try {
            const page = await listUsers(usersPaging);
            setPage(page);
        } catch (error) {
            setError(error);
        }
        setLoading(false);
    }, [setLoading, setPage, setError]);

    useEffect(() => {
        load().then();
    }, []);

    const create = async () => {
        setLoading(true);
        try {
            const user = await createUser(form);
            history.push('/users/' + user.uuid);
            return;
        } catch (error) {
            setError(error);
        }
        setLoading(false);
    }

    const showCell = (column: keyof typeof columns) => columns[column] && columns[column].show;

    const canCreate = useMemo(() => {
        return (form.role && form.email && !loading);
    }, [form, loading]);

    return (
        <Fragment>
            <Box my={1}>
                <Breadcrumbs>
                    <Crumb label={messages.users.plural} />
                </Breadcrumbs>
            </Box>
            <Grid container spacing={gs} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{flexGrow: 1}}>
                    <Typography variant="h4" gutterBottom>{messages.users.plural}</Typography>
                </Grid>
                <Grid item>
                    {/*    TODO: TABLE SEACH INPUT */}
                </Grid>
            </Grid>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <Paper>
                        {page && (
                            <ContentTable page={page} paging={usersPaging}
                                          inProgress={loading}
                                          columns={columns}
                                          onPagingChange={() => load()}
                                          onColumnsChange={setColumns}
                                          renderTableBody={(
                                              <Fragment>
                                                  {page.content.map(u => (
                                                      <TableRow key={u.uuid} onClick={() => history.push('/users/' + u.uuid)}>
                                                          <TableCell style={{ width: 130 }}><UserRoleDisplayComponent role={u.role} /></TableCell>
                                                          <TableCell>{u.email}</TableCell>
                                                      </TableRow>
                                                  ))}
                                              </Fragment>
                                          )}

                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
            <AddFab onClick={() => setCreateMode(true)} />
            <Dialog open={createMode} onClose={() => setCreateMode(false)} fullWidth maxWidth="sm">
                <DialogTitle>{messages.users.actions.add}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Selector label={messages.users.role}
                                      value={form.role}
                                      options={[
                                          { label: messages.users.selectRole, value: '' },
                                          ...Object.values(UserRole).map(r => ({ label: r, value: r }))
                                      ]}
                                      onChange={role => setForm({ ...form, role })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput label={messages.common.email}
                                       value={form.email}
                                       onChange={email => setForm({ ...form, email })}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCreateMode(false)}>{messages.actions.cancel}</Button>
                    <Button variant="contained" color="primary" disabled={!canCreate} onClick={create}>{messages.actions.create}</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}