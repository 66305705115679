import * as React from "react";
import {createElement} from "react";
import {Chip} from "@material-ui/core";
import {messages} from "../../i18n";
import {green, red} from "@material-ui/core/colors";

interface DisabilityAccessComponentProps {
    disabilityAccess?: boolean;
    size?: 'small' | 'medium';
}

export function DisabilityAccessComponent(props: DisabilityAccessComponentProps) {
    const {disabilityAccess, size} = props;
    const disabledColor = { backgroundColor: red[500], color: '#FFFFFF' };
    const enabledColor = { backgroundColor: green[500], color: '#FFFFFF' };
    const color = disabilityAccess ? disabledColor : enabledColor;
    const label = disabilityAccess ? messages.common.disabled : messages.common.enabled;
    return (<Chip label={label} style={{...color}} size={size} />);
}
