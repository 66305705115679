import {createElement} from "react";
import {Chip} from "@material-ui/core";
import {Cloud, CloudOff} from "@material-ui/icons";
import {messages} from "../../i18n";

interface CubeOnlineComponentProps {
    connected: boolean;
}

export function CubeConnectedComponent(props: CubeOnlineComponentProps) {
    const {connected} = props;
    const icon = connected ? <Cloud /> : <CloudOff />;
    const label = connected ? messages.cubes.connected : messages.cubes.notConnected;
    const color = connected ? '#4caf50' : '#f44336';
    return (
        <Chip label={label} style={{color: '#ffffff', backgroundColor: color}} icon={icon} />
    );
}
