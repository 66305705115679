import {createElement, useCallback} from "react";
import {IconButton} from "@material-ui/core";
import {MapIcon} from "../icons";

interface GoogleMapsProps {
    latitude: number;
    longitude: number;
}

export function GoogleMaps(props: GoogleMapsProps) {
    const {latitude, longitude} = props;
    const handleClick = useCallback(() => {
        const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        window.open(url, 'gmaps');
    }, [latitude, longitude]);

    return (
        <IconButton onClick={handleClick}>
            <MapIcon />
        </IconButton>
    );
}
