import {createElement} from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    empty: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '400px',
        maxHeight: '400px',
        width: '40%',
        height: '40%'
    }
}));

interface BlockSvgProps {
    src: string
}

export function BlockSvg(props: BlockSvgProps) {
    const classes = useStyles();
    return(<img className={classes.empty} src={props.src} />);
}
