import {createElement, useState} from "react";
import {useHistory} from "react-router";
import {messages} from "../i18n";
import {AppNavbar, VCLogo} from "@variocube/app-ui";

type NavItem = { key: string, title: string, active?: boolean, prioritised?: boolean, onClick?: () => void };

export const NavBar = () => {
    const history = useHistory();
    const [navItems, setNavItems] = useState<NavItem[]>([
        { key: 'deliveries', title: messages.deliveries.plural, onClick: () => handleNavClick('/deliveries'), prioritised: true } as any,
        { key: 'cubes', title: messages.cubes.plural, onClick: () => handleNavClick('/cubes'), prioritised: true },
        { key: 'users', title: messages.users.plural, onClick: () => handleNavClick('/users') }
    ]);

    const handleNavClick = (path: string) => {
        const pathDirs = path.split('/').filter(v => v);
        if (pathDirs.length > 0) {
            setNavItems(navItems.map(i => {
                i.active = (i.key === pathDirs[0]);
                return i;
            }))
        }
        history.push(path);
    };

    return (
        <AppNavbar navItems={navItems}
                   appName={messages.appName}
                   logoPath={VCLogo}
        />
    )
}
