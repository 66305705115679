import { Component, createElement } from "react";
import { DateTime } from "luxon";
import React = require("react");
import {luxonDate} from "../tools";

export enum Display {
    Timestamp = "Timestamp",
    Age = "Age",
    Date = "Date",
    Time = "Time",
}

type Properties = {
    date: DateTime | Date | string | number | undefined,
    display?: Display,
}

type State = {
    display: Display | undefined
}

export class DateComponent extends Component<Properties, State> {

    constructor(props: Properties) {
        super(props);
        this.state = {
            display: undefined
        };
    }

    private computeDisplay() {
        if(this.state.display != undefined) {
            return this.state.display;
        }
        if(this.props.display != undefined) {
            return this.props.display;
        }
        return Display.Age;
    }

    handleDisplayClicked() {
        const currentDisplay = this.computeDisplay();
        let nextDisplay: Display;
        switch(currentDisplay) {
            case Display.Age:
                if(this.props.display != undefined) {
                    nextDisplay = this.props.display;
                }else {
                    nextDisplay = Display.Timestamp;
                }
                break;
            case Display.Timestamp:
            case Display.Date:
            case Display.Time:
                nextDisplay = Display.Age;
                break;
            default:
                throw new Error(`Unknown Display enum constant ${currentDisplay}`);
        }
        this.setState({ display: nextDisplay });
    }

    render() {
        const currentDisplay = this.computeDisplay();
        const date = luxonDate(this.props.date);
        let formatted: string | null;
        switch(currentDisplay) {
            case Display.Age:
                formatted = date.toRelative();
                break;
            case Display.Timestamp:
                formatted = date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
                break;
            case Display.Date:
                formatted = date.toLocaleString(DateTime.DATE_SHORT);
                break;
            case Display.Time:
                formatted = date.toLocaleString(DateTime.TIME_24_WITH_SECONDS);
                break;
            default:
                throw new Error(`Unknown Display enum constant ${currentDisplay}`);
        }
        if(!formatted) {
            throw new Error('Could not format date ' + date);
        }
        return (<span onClick={() => this.handleDisplayClicked()} style={{cursor:'pointer'}}> { formatted } </span>)
    }
}
