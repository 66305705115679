import {createElement, PropsWithChildren} from "react";
import {Typography} from "@material-ui/core";

export const LabeledData = ({label, children}: PropsWithChildren<{ label: string }>) => {
    return (
        <div>
            <Typography variant="overline">{label}</Typography>
            <Typography variant="body1" component="div">{children}</Typography>
        </div>
    )
}