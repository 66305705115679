import {I18nResolver} from "i18n-ts";

const en = {
    vendorName: 'VARIOCUBE',
    appName: 'A1 Integration',
    loading: 'Your data is loading ... ',
    common: {
        name: 'Name',
        lastName: 'Last name',
        firstName: 'First name',
        description: 'Description',
        email: 'Email',
        phone: 'Mobile',
        state: 'State',
        type: 'Type',
        imageUrl: 'Image URL',
        timeframe: 'Timeframe',
        from: 'From',
        until: 'Until',
        created: 'Created',
        updated: 'Updated',
        enabled: 'Enabled',
        disabled: 'Disabled'
    },
    actions: {
        create: 'Create',
        edit: 'Edit',
        save: 'Save',
        delete: 'Delete',
        cancel: 'Cancel',
        search: 'Search',
        choose: 'Choose',
        confirm: 'Confirm',
        view: 'View',
        back: 'Back',
        next: 'Next',
        addImage: 'Add Image',
        close: 'Close'
    },
    error: {
        details: 'Error Details',
        generic: {
            title: 'Something went wrong',
            message: 'An error occurred, please try again. If this issue persists, please contact our support.'
        },
        badRequest: {
            title: 'Invalid data',
            message: 'One or more of the data was not valid. Please verify your input and try again.'
        },
        notFound: {
            title: 'Resource not available',
            message: 'We couldn\'t find your requested resource.'
        },
        input: {
            requiredField: 'This field is required and must not be empty.'
        }
    },
    imprint: 'Imprint',
    footerBar: {
        switchTenant: 'Switch Tenant',
        api: 'API'
    },
    notFound: {
        title: '404: Not found',
        info: (path: string) => `We could not find what you were looking for at path: ${path}.`,
        home: 'Back to home'
    },
    notAvailable: 'Not available',
    deliveries: {
        singular: 'Delivery',
        plural: 'Deliveries',
        parcelNumber: 'Parcel number',
        stored: 'Stored at',
        ended: 'Ended at',
        pickedUp: 'Picked-up at',
        states: {
            Created: 'Created',
            Stored: 'Stored',
            PickedUp: 'Picked Up',
            Canceled: 'Canceled',
            Retrieved: 'Retrieved'
        },
        service: 'Service',
        services: {
            Outbound: 'Outbound',
            Inbound: 'Inbound'
        },
        cubeBox: 'Cube / Box',
        accessCodes: 'Access Codes',
        signature: 'Signature',
        sender: {
            name: 'Name',
            email: 'E-Mail',
            phone: 'Phone'
        },
        recipientPickupCode: 'Recipient Pickup Code',
        carrierDeliveryCode: 'Carrier Delivery Code',
        carrierRetrievalCode: 'Carrier Retrieval Code',
        journal: 'Journal',
        noDeliveriesFound: 'No deliveries found',
        maxStorageDays: 'Max storage days exceeded',
        maxStorageDaysNotified: 'Delivery exceeds maximum allowed storage days.',
        criticalStorageDays: 'Critical storage days exceeded',
        criticalStorageDaysNotified: 'Delivery exceeds critical storage days. Tenant has been notified.',
        carrierNotifications: 'Carrier notifications',
        disabilityAccess: 'Disability Access',
        parcelNumberHelperText: 'DPD parcel numbers must have 14 characters',
        create: {
            title: 'Create delivery',
            selectCube: 'Select cube',
            selectCubeFirst: 'Please select a cube first',
            selectBox: 'Select box'
        },
        cancel: {
            title: 'Cancel delivery',
            message: 'Are you sure you want to cancel this with A1 delivery?'
        },
        delete: {
            title: 'Delete delivery',
            message: 'Are you sure you want to delete this delivery?'
        },
        markMaxStorageDays: {
            title: 'Mark max storage days',
            message: 'Warning: This action will notified the carrier and revoke pick-up access from the recipient.'
        },
        notFound: (deliveryUuid: string) => `Cannot find delivery with uuid ${deliveryUuid}.`,
        eventQueue: 'Event Queue',
        noEvents: (carrierId: string) => `No events yet sent to carrier: ${carrierId}.`,
        events: {
            executionState: 'Execution',
            deliveryState: 'State',
            deliveryAction: 'Action',
            createdAt: 'Created At',
            retryAt: 'Retry At',
            deliveredAt: 'Delivered At',
            expiredAt: 'Expired At',
            failures: 'Failures',
            scannedBarcode: 'Scanned Bar-Code',
            code: 'Used Code',
            signature: 'Signature',
            name: 'Sender-Name',
            email: 'E-Mail',
            phone: 'Phone',
            response: 'Response',
            retry: 'Retry',
            retrySuccess: 'Event will be retried in a few seconds.'
        },
        reservation: {
            title: 'Reservation',
            start: 'Start',
            end: 'End'
        },
        mockEvents: {
            title: 'Trigger events'
        },
        callback: {
            title: 'Callback',
            url: 'URL',
            method: 'Method'
        },
        foreignId: 'Foreign-Id',
        notes: 'Notes',
    },
    cubes: {
        singular: 'Cube',
        plural: 'Cubes',
        cubeId: 'ID',
        description: 'Description',
        connectionState: 'Connection-State',
        connected: 'Online',
        notConnected: 'Offline',
        address: 'Address',
        location: 'GPS-Location',
        image: 'Cube Image',
        downtimes: 'Down times',
        startDate: 'Start date',
        endDate: 'End date',
        comment: 'Comment',
        availability: {
            label: 'Availability',
            total: 'Total',
            small: 'S',
            medium: 'M',
            large: 'L',
            xlarge: 'XL'
        },
        accessCodes: {
            title: 'Access Codes',
            carrier: 'Carrier',
            carrierNone: 'None configured for carriers',
            carrierAccessCode: {
                title: 'New Carrier Access Code',
                carrierId: 'Carrier Id',
                accessCode: 'Access Code',
                pin: 'PIN'
            },
            readOnly: 'Read-Only',
            nonOccupied: 'Non-occupied',
            fullAccess: 'Full Access'
        },
        noCubes: 'No cubes found, please contact support.'
    },
    boxes: {
        singular: 'Box',
        plural: 'Boxes'
    },
    users: {
        singular: 'User',
        plural: 'Users',
        noUsers: 'No users',

        id: 'ID',
        language: 'Language',
        receiveEmails: 'Receive notification emails',
        accessCode: 'Access code',

        role: 'User role',
        selectRole: 'Select user role',

        data: 'User data',
        changePassword: 'Change password',

        requiredApproval: 'Your account is currently being reviewed. Please be patient, one of the administrator will review your data and notify you once your account is activated.',

        create: {
            title: 'Create user'
        },
        edit: {
            title: 'Edit user'
        },
        delete: {
            title: 'Delete user',
            prompt: 'Are you sure you want to delete this user?',
            hint: 'This action cannot be undone.'
        },
        approval: {
            title: 'Approve user',
            prompt: 'Are you sure you want to approve this user?',
        },
        actions: {
            signup: 'Signup',
            add: 'Add User'
        },
        roles: {
            TenantAdmin: 'Admin',
            TenantUser: 'User',
            Borrower: 'Borrower'
        },
        signupInformation: {
            title: 'Sign-Up Information',
            notConfigured: {
                title: 'Not configured',
                info: 'Your system is not configured to allow users to sign up.',
                goSettings: 'Go to Settings'
            },
            infoDomains: 'Users can sign up at any of the following URLs.',
            infoEmails: 'Users need to sign up with any of the following E-Mail domains.'
        }
    },
    fulltextSearch: 'Full text search',
};

const de = {
    vendorName: 'VARIOCUBE',
    appName: 'A1 Integration',
    loading: 'Daten werden geladen ... ',
    common: {
        name: 'Name',
        lastName: 'Nachname',
        firstName: 'Vorname',
        description: 'Beschreibung',
        email: 'E-Mail',
        phone: 'Mobiltelefon',
        state: 'Status',
        type: 'Typ',
        imageUrl: 'Bild-URL',
        timeframe: 'Zeitrahmen',
        from: 'Von',
        until: 'Bis',
        created: 'Angelegt',
        updated: 'Aktualisiert',
        enabled: 'Aktiviert',
        disabled: 'Deaktiviert'
    },
    actions: {
        create: 'Anlegen',
        edit: 'Bearbeiten',
        save: 'Speichern',
        delete: 'Löschen',
        cancel: 'Abbrechen',
        search: 'Suche',
        choose: 'Auswählen',
        confirm: 'Bestätigen',
        view: 'Ansicht',
        back: 'Zurück',
        next: 'Weiter',
        addImage: 'Bild hinzufügen',
        close: 'Schließen'
    },
    error: {
        details: 'Fehler-Details',
        generic: {
            title: 'Etwas ist schief gelaufen',
            message: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut. Wenn dieses Problem weiterhin besteht, wenden Sie sich bitte an unseren Support.'
        },
        notFound: {
            title: 'Daten nicht verfügbar',
            message: 'Wir konnten die gewünschte Anfrage nicht finden.'
        },
        badRequest: {
            title: 'Ungültige Daten',
            message: 'Eine oder mehrere Daten waren nicht gültig. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.',
        },
        input: {
            requiredField: 'Dieses Feld ist pflicht und es darf nicht leer sein.'
        }
    },
    imprint: 'Impressum',
    footerBar: {
        switchTenant: 'Mandant wechseln',
        api: 'API'
    },
    notFound: {
        title: '404: Nicht gefunden',
        info: (path: string) => `Wir konnten keine Seite für die URL ${path} finden.`,
        home: 'Startseite aufrufen'
    },
    notAvailable: 'Nicht vorhanden',
    deliveries: {
        singular: 'Sendung',
        plural: 'Sendungen',
        parcelNumber: 'Sendungsnummer',
        stored: 'Eingelagert',
        ended: 'Beendet',
        pickedUp: 'Abgeholt',
        states: {
            Created: 'Angelegt',
            Stored: 'Eingelagert',
            PickedUp: 'Abgeholt',
            Canceled: 'Abgebrochen',
            Retrieved: 'Entnommen'
        },
        service: 'Service',
        services: {
            Outbound: 'Ausgehend',
            Inbound: 'Eingehend'
        },
        cubeBox: 'Cube / Fach',
        accessCodes: 'Zugriffscodes',
        signature: 'Unterschrift',
        sender: {
            name: 'Name',
            email: 'E-Mail',
            phone: 'Phone'
        },
        recipientPickupCode: 'Abholcode Empfänger',
        carrierDeliveryCode: 'Einlagerungscode KEP',
        carrierRetrievalCode: 'Entnahmecode KEP',
        journal: 'Verlauf',
        noDeliveriesFound: 'Keine Sendungen gefunden',
        maxStorageDays: 'Maximale Lagertage überschritten',
        maxStorageDaysNotified: 'Die Lieferung überschreitet die maximal zulässigen Lagertage.',
        criticalStorageDays: 'Kritische Lagertage überschritten',
        criticalStorageDaysNotified: 'Lieferung überschreitet kritische Lagertage. Der Mieter wurde benachrichtigt.',
        disabilityAccess: 'Behindertengerecht',
        parcelNumberHelperText: 'DPD Paketnummern sind 14 Zeichen lang',
        create: {
            title: 'Sendung erstellen',
            selectCube: 'Cube auswählen',
            selectCubeFirst: 'Bitte wählen Sie zuerst einen Würfel',
            selectBox: 'Fach auswählen'
        },
        cancel: {
            title: 'Sendung stornieren',
            message: 'Wollen Sie die Sendung bei A1 wirklich stornieren?'
        },
        delete: {
            title: 'Sendung löschen',
            message: 'Wollen Sie wirklich diese Sendung läschen?'
        },
        markMaxStorageDays: {
            title: 'Maximale Lagerdauer markieren',
            message: 'Warnung: Diese Aktion benachrichtigt den Spediteur und entzieht dem Empfänger den Zugang zur Abholung.'
        },
        notFound: (deliveryUuid: string) => `Sending mit der ${deliveryUuid} kann nicht gefunden werden.`,
        eventQueue: 'Event Queue',
        noEvents: (carrierId: string) => `Es wurden noch keine Events an den KEP Dienstleister ${carrierId} übermittelt.`,
        events: {
            executionState: 'Ausführung',
            deliveryState: 'Status',
            deliveryAction: 'Aktion',
            createdAt: 'Angelegt',
            retryAt: 'Wiederholung',
            deliveredAt: 'Zugestellt',
            expiredAt: 'Abgelaufen',
            failures: 'Versuche',
            scannedBarcode: 'Gescannter Bar-Code',
            code: 'Verwendeter Code',
            signature: 'Unterschrift',
            name: 'Absender',
            email: 'E-Mail',
            phone: 'Telefon',
            response: 'Service-Antwort',
            retry: 'Wiederholen',
            retrySuccess: 'Ereignis wird in wenigen Sekunden erneut übertragen.'
        },
        reservation: {
            title: 'Reservierung',
            start: 'Von',
            end: 'Bis'
        },
        mockEvents: {
            title: 'Event simulieren'
        },
        callback: {
            title: 'Callback',
            url: 'URL',
            method: 'Method'
        },
        foreignId: 'Foreign-Id',
        notes: 'Notizen',
    },
    cubes: {
        singular: 'Cube',
        plural: 'Cubes',
        cubeId: 'ID',
        description: 'Beschreibung',
        connectionState: 'Verbindungsstatus',
        connected: 'Online',
        notConnected: 'Offline',
        address: 'Adresse',
        location: 'GPS-Position',
        image: 'Bild',
        downtimes: 'Ausfallzeiten',
        startDate: 'Startdatum',
        endDate: 'Enddatum',
        comment: 'Anmerkung',
        availability: {
            label: 'Verfügbarkeit',
            total: 'Gesamt',
            small: 'S',
            medium: 'M',
            large: 'L',
            xlarge: 'XL'
        },
        accessCodes: {
            title: 'Zugriffscodes',
            carrier: 'KEP Abholcode',
            carrierNone: 'Keine Zugriffscodes konfiguriert',
            carrierAccessCode: {
                title: 'Neuer KEP Abholcode',
                carrierId: 'Carrier Id',
                accessCode: 'Zugriffscode',
                pin: 'PIN'
            },
            readOnly: 'Nur Lesezugriff',
            nonOccupied: 'Nur nicht belegte',
            fullAccess: 'Vollzugriff'
        },
        noCubes: 'Keine Cubes gefunden, bitte kontaktieren Sie den Support.'
    },
    boxes: {
        singular: 'Fach',
        plural: 'Fächer'
    },
    users: {
        singular: 'Benutzer',
        plural: 'Benutzer',
        noUsers: 'Keine Benutzer gefunden',

        apiToken: 'API-Token',

        id: 'ID',
        language: 'Sprache',
        receiveEmails: 'Benachrichtigungs-E-Mails erhalten',
        accessCode: 'Zugangscode',

        role: 'Benutzer-Rolle',
        selectRole: 'Benutzer-Rolle auswählen',

        data: 'Benutzerdaten',
        changePassword: 'Passwort ändern',

        requiredApproval: 'Ihr Konto wird gerade überprüft. Bitte haben Sie etwas Geduld, einer der Administratoren wird Ihre Daten überprüfen und Sie benachrichtigen, sobald Ihr Konto aktiviert ist.',

        create: {
            title: 'Benutzer erstellen'
        },
        edit: {
            title: 'Benutzer bearbeiten'
        },
        delete: {
            title: 'Benutzer löschen',
            prompt: 'Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?',
            hint: 'Diese Aktion kann nicht rückgängig gemacht werden.'
        },
        approval: {
            title: 'Benutzer freigeben',
            prompt: 'Sind Sie sicher, dass Sie diesen Benutzer freigeben möchten?',
        },
        actions: {
            signup: 'Registrierung',
            add: 'Benutzer hinzufügen'
        },
        roles: {
            TenantAdmin: 'Administrator',
            TenantUser: 'Benutzer',
            Borrower: 'Entlehner'
        },
        signupInformation: {
            title: 'Sign-Up Information',
            notConfigured: {
                title: 'Nicht konfiguriert',
                info: 'Ihr System ist nicht für die Registrierung von Entlehnern konfiguriert.',
                goSettings: 'Einstellungen'
            },
            infoDomains: 'Entlehner können sich unter folgenden URLs registrieren.',
            infoEmails: 'Entlehner müssen eine der folgenden E-Mail Domains nutzen um sich zu registrieren.'
        }
    },
    fulltextSearch: 'Volltextsuche',
};


const enOLD = {
    // Generic labels
    vendorName: 'VARIOCUBE',
    appName: 'A1 Integration',
    loading: 'Your data is loading ... ',
    delete: 'Delete',
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    create: 'Create',
    add: 'Add',
    edit: 'Edit',
    approve: 'Approve',
    save: 'Save',
    back: 'Back',
    next: 'Next',
    imprint: 'Imprint',
    enabled: 'Enabled',
    disabled: 'Disabled',
    footerBar: {
        switchTenant: 'Switch Tenant',
        api: 'API'
    },
    notFound: {
        title: '404: Not found',
        info: (path: string) => `We could not find what you were looking for at path: ${path}.`,
        home: 'Back to home'
    },
    notAvailable: 'Not available',

    auth: {
        login: 'Login',
        loginTitle: 'Login',
        loginInfo: 'We are redirecting you to our Identity Provider (Auth0) ...',
        logoutTitle: 'Logout',
        logoutInfo: 'You are being logged out ...',
        processing: 'Login is being processed ...',
        error: 'An error occured processing your login. Please try again.'
    },

    cubes: {
        singular: 'Cube',
        plural: 'Cubes',
        cubeId: 'ID',
        description: 'Description',
        connectionState: 'Connection-State',
        connected: 'Online',
        notConnected: 'Offline',
        address: 'Address',
        location: 'GPS-Location',
        image: 'Cube Image',
        downtimes: 'Down times',
        startDate: 'Start date',
        endDate: 'End date',
        comment: 'Comment',
        boxes: {
            singular: 'Box',
            plural: 'Boxes',
            lockState: {
                label: 'Lock-State',
                Open: 'Open',
                Closed: 'Closed',
                Blocked: 'Blocked',
                Breakin: 'Break-In',
                unknown: 'Unknown'
            },
            boxType: 'Type',
            openBox: 'Open Box',
            openBoxInfoEmpty: (boxDescription: string) => `Are you sure you want to open the empty box ${boxDescription}?`,
            openBoxInfoOccupied: (boxDescription: string) => `Are you sure you want to open the occupied box ${boxDescription}?`,
            available: 'Available',
            enabled: 'Ja',
            disabled: 'Disabled',
            disabledInfo: 'Box is currently disabled and cannot be occupied. Items currently stored in the box can still be picked up.',
            enableBox: 'Enable Box',
            enableBoxInfo: (boxDescription: string) => `Are you sure you want to enable box ${boxDescription}?`,
            disableBox: 'Disable Box',
            disableBoxInfo: (boxDescription: string) => `Are you sure you want to disable box ${boxDescription}?`,

            occupancy: 'Occupancy',
            noBoxes: 'No boxes found.',
            noOccupancies: 'This box is currently not occupied.'
        },
        availability: {
            label: 'Availability',
            total: 'Total',
            small: 'S',
            medium: 'M',
            large: 'L',
            xlarge: 'XL'
        },
        accessCodes: {
            title: 'Access Codes',
            carrier: 'Carrier',
            carrierNone: 'None configured for carriers',
            carrierAccessCode: {
                title: 'New Carrier Access Code',
                carrierId: 'Carrier Id',
                accessCode: 'Access Code',
                pin: 'PIN'
            },
            readOnly: 'Read-Only',
            nonOccupied: 'Non-occupied',
            fullAccess: 'Full Access'
        },
        noCubes: 'No cubes found, please contact support.'
    },

    boxes: {
        singular: 'Box',
        plural: 'Boxes'
    },

    deliveries: {
        singular: 'Delivery',
        plural: 'Deliveries',
        state: 'State',
        tenant: 'Tenant',
        parcelNumber: 'Parcel number',
        created: 'Created at',
        ended: 'Ended at',
        stored: 'Stored at',
        pickedUp: 'Picked-up at',
        stateEnum: {
            Created: 'Created',
            Stored: 'Stored',
            PickedUp: 'Picked Up',
            Canceled: 'Canceled',
            Retrieved: 'Retrieved'
        },
        service: 'Service',
        serviceEnum: {
            Outbound: 'Outbound',
            Inbound: 'Inbound'
        },
        cubeBox: 'Cube / Box',
        accessCodes: 'Access Codes',
        signature: 'Signature',
        sender: {
            name: 'Name',
            email: 'E-Mail',
            phone: 'Phone'
        },
        recipientPickupCode: 'Recipient Pickup Code',
        carrierDeliveryCode: 'Carrier Delivery Code',
        carrierRetrievalCode: 'Carrier Retrieval Code',
        journal: 'Journal',
        noDeliveriesFound: 'No deliveries found',
        maxStorageDays: 'Max storage days exceeded',
        maxStorageDaysNotified: 'Delivery exceeds maximum allowed storage days.',
        criticalStorageDays: 'Critical storage days exceeded',
        criticalStorageDaysNotified: 'Delivery exceeds critical storage days. Tenant has been notified.',
        carrierNotifications: 'Carrier notifications',
        disabilityAccess: 'Disability Access',
        parcelNumberHelperText: 'DPD parcel numbers must have 14 characters',
        create: {
            title: 'Create delivery',
            selectCube: 'Select cube',
            selectCubeFirst: 'Please select a cube first',
            selectBox: 'Select box'
        },
        cancel: {
            title: 'Cancel delivery',
            message: 'Are you sure you want to cancel this with A1 delivery?'
        },
        delete: {
            title: 'Delete delivery',
            message: 'Are you sure you want to delete this delivery?'
        },
        markMaxStorageDays: {
            title: 'Mark max storage days',
            message: 'Warning: This action will notified the carrier and revoke pick-up access from the recipient.'
        },
        notFound: (deliveryUuid: string) => `Cannot find delivery with uuid ${deliveryUuid}`,
        eventQueue: 'Event Queue',
        noEvents: (carrierId: string) => `No events yet sent to carrier: ${carrierId}.`,
        events: {
            executionState: 'Execution',
            deliveryState: 'State',
            deliveryAction: 'Action',
            createdAt: 'Created At',
            retryAt: 'Retry At',
            deliveredAt: 'Delivered At',
            expiredAt: 'Expired At',
            failures: 'Failures',
            scannedBarcode: 'Scanned Bar-Code',
            code: 'Used Code',
            signature: 'Signature',
            name: 'Sender-Name',
            email: 'E-Mail',
            phone: 'Phone',
            response: 'Response',
            retry: 'Retry',
            retrySuccess: 'Event will be retried in a few seconds.'
        },
        reservation: {
            title: 'Reservation',
            start: 'Start',
            end: 'End'
        },
        notes: 'Notes',
        filter: {
            fulltext: 'Search'
        },
        mockEvents: {
            title: 'Trigger events'
        },
        callback: {
            title: 'Callback',
            url: 'URL',
            method: 'Method'
        },
        foreignId: 'Foreign-Id'
    },

    tenants: {
        singular: 'Tenant',
        plural: 'Tenants',
        select: 'Please select the tenant you intend to work with from the following list.',
        noTenant: {
            title: 'No tenant found',
            info: 'Your user is not assigned to any tenant, please contact support to resolve this issue.'
        }
    },

    users: {
        singular: 'User',
        plural: 'Users',
        noUsers: 'No users',
        noCostCenters: 'User is not assigned to any cube center.',

        id: 'ID',
        firstName: 'First name',
        lastName: 'Last name',
        name: 'Name',
        email: 'Email',
        language: 'Language',
        receiveEmails: 'Receive notification emails',
        accessCode: 'Access code',
        state: 'State',

        role: 'User role',
        selectRole: 'Select user role',

        costCenters: 'Cost centers',
        selectCostCenter: 'Select cost center',
        costCenterSelectCount: 'Cost centers selected',

        data: 'User data',
        changePassword: 'Change password',

        requiredApproval: 'Your account is currently being reviewed. Please be patient, one of the administrator will review your data and notify you once your account is activated.',

        create: {
            title: 'Create user'
        },
        edit: {
            title: 'Edit user'
        },
        delete: {
            title: 'Delete user',
            prompt: 'Are you sure you want to delete this user?',
            hint: 'This action cannot be undone.'
        },
        approval: {
            title: 'Approve user',
            prompt: 'Are you sure you want to approve this user?',
        },
        actions: {
            signup: 'Signup',
            add: 'Add User'
        },
        roles: {
            TenantAdmin: 'Admin',
            TenantUser: 'User',
            Borrower: 'Borrower'
        },
        signupInformation: {
            title: 'Sign-Up Information',
            notConfigured: {
                title: 'Not configured',
                info: 'Your system is not configured to allow users to sign up.',
                goSettings: 'Go to Settings'
            },
            infoDomains: 'Users can sign up at any of the following URLs.',
            infoEmails: 'Users need to sign up with any of the following E-Mail domains.'
        }
    },

    carrierTokens: {
        singular: 'Carrier Token',
        plural: 'Carrier Tokens',
        noTokens: 'No carrier token',
        carrierId: 'Carrier Id',
        apiToken: 'API-Token',
        apiTokenHint: 'This token will only display once. Make sure to save it on your machine before closing this window.',
        create: {
            title: 'Create carrier token',
            result: 'Carrier token created'
        }
    },

    delivery: 'Delivery',
    foreignId: 'Foreign Id',
    recipients: 'Recipients',
    createDate: 'Create Date',
    locker: 'Locker',
    state: 'State',
    filterNoCriteria: 'Click here to filter',
    filter: 'Filter',
    filterCubes: 'Lockers',
    filterStates: 'States',
    fulltextSearch: 'Fulltext',
    periodUnit: 'Period Unit',
    periodUnitHours: 'Hours',
    periodUnitDays: 'Days',
    periodUnitWeeks: 'Weeks',
    calculationCase: 'Calculation Case',
    calculationCasePerUsage: 'Per Usage',
    calculationCasePerHour: 'Per Hour',
    calculationCasePerDay: 'Per Day',
    amount: {
        net: 'Net',
        gross: 'Gross',
        tax: 'Tax',
        taxRate: 'Tax rate',
    },

    settings: {
        title: 'Settings',
        accessCode: 'Access Code',
        refreshAccessCode: 'Refresh access code',
        changePassword: 'Change password',
        password: 'Password',
        confirmPassword: 'Confirm password',
        passwordChanged: 'Your password has been changed.',
        invalidPassword: 'Password and confirm password not match or they are too weak.',

        tenantTitle: "Tenant settings",
    },

    signup: {
        title: 'Sign Up',
        email: 'Email address',
        password: 'Password',
        confirmPassword: 'Confirm password',
        termAgreementHint: 'I have read and agree to VARIOCUBE Rental Locker Terms of Usage.',
        passwordPolicyError: 'Password must have at least 8 letters and contain lower case, upper case, number and special characters.',
        passwordNotMatch: 'Passwords not match.',
        success: {
            requireApproval: 'Thank you for your registration. Please be patient, one of the administrator will review your data and notify you once your account is activated.',
            automaticallyApproval: 'Thank you your registration. You will be redirect to login shortly...'
        }
    },

    errorDialogTitle: "Something went wrong",
    errorDialogMessage: "An error occurred, please try again. If this issue persists, please contact our support."
};

const deOLD = {
    // Generic labels
    vendorName: 'VARIOCUBE',
    appName: 'A1 Integration',
    loading: 'Daten werden geladen ... ',
    delete: 'Löschen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    confirm: 'Bestätigen',
    create: 'Anlegen',
    add: 'Hinzufügen',
    edit: 'Bearbeiten',
    approve: 'Freigeben',
    save: 'Speichern',
    back: 'Zurück',
    next: 'Weiter',
    imprint: 'Impressum',
    enabled: 'Aktiviert',
    disabled: 'Deaktiviert',
    footerBar: {
        switchTenant: 'Mandant wechseln',
        api: 'API'
    },
    notFound: {
        title: '404: Nicht gefunden',
        info: (path: string) => `Wir konnten keine Seite für die URL ${path} finden.`,
        home: 'Startseite aufrufen'
    },
    notAvailable: 'Nicht vorhanden',

    auth: {
        login: 'Anmelden',
        loginTitle: 'Anmeldung',
        loginInfo: 'Sie werden zu unserem Identity Provider (Auth0) weitergeleitet ...',
        logoutTitle: 'Abmelden',
        logoutInfo: 'Sie werden abgemeldet ...',
        processing: 'Login wird verarbeitet ...',
        error: 'Ihr Login konnte nicht verarbeitet werden. Bitte versuchen Sie es erneut.'
    },

    cubes: {
        singular: 'Cube',
        plural: 'Cubes',
        cubeId: 'ID',
        description: 'Beschreibung',
        connectionState: 'Verbindungsstatus',
        connected: 'Online',
        notConnected: 'Offline',
        address: 'Adresse',
        location: 'GPS-Position',
        image: 'Bild',
        downtimes: 'Ausfallzeiten',
        startDate: 'Startdatum',
        endDate: 'Enddatum',
        comment: 'Anmerkung',
        boxes: {
            singular: 'Fach',
            plural: 'Fächer',
            lockState: {
                label: 'Status',
                Open: 'Offen',
                Closed: 'Geschlossen',
                Blocked: 'Blockiert',
                Breakin: 'Einbruch',
                unknown: 'Unbekannt'
            },
            boxType: 'Typ',
            openBox: 'Fach öffnen',
            openBoxInfoEmpty: (boxDescription: string) => `Sind Sie sicher, dass Sie das leere Fach ${boxDescription} öffnen möchten?`,
            openBoxInfoOccupied: (boxDescription: string) => `Sind Sie sicher, dass Sie das belegte Fach ${boxDescription} öffnen möchten?`,
            available: 'Verfügbar',
            enabled: 'Ja',
            disabled: 'Deaktiviert',
            disabledInfo: 'Fach ist deaktiviert und kann nicht belegt werden. Eventuell aktuell eingelagerte Sendungen können noch abgeholt werden.',
            enableBox: 'Fach aktivieren',
            enableBoxInfo: (boxDescription: string) => `Sind Sie sicher, dass Sie Fach ${boxDescription} deaktivieren möchten?`,
            disableBox: 'Fach deaktivieren',
            disableBoxInfo: (boxDescription: string) => `Sind Sie sicher, dass Sie Fach ${boxDescription} aktivieren möchten?`,
            occupancy: 'Belegung',
            noBoxes: 'Keine Fächer gefunden.',
            noOccupancies: 'Dieses Fach ist aktuell nicht belegt.'
        },
        availability: {
            label: 'Verfügbarkeit',
            total: 'Gesamt',
            small: 'S',
            medium: 'M',
            large: 'L',
            xlarge: 'XL'
        },
        accessCodes: {
            title: 'Zugriffscodes',
            carrier: 'KEP Abholcode',
            carrierNone: 'Keine Zugriffscodes konfiguriert',
            carrierAccessCode: {
                title: 'Neuer KEP Abholcode',
                carrierId: 'Carrier Id',
                accessCode: 'Zugriffscode',
                pin: 'PIN'
            },
            readOnly: 'Nur Lesezugriff',
            nonOccupied: 'Nur nicht belegte',
            fullAccess: 'Vollzugriff'
        },
        noCubes: 'Keine Cubes gefunden, bitte kontaktieren Sie den Support.'
    },

    boxes: {
        singular: 'Fach',
        plural: 'Fächer'
    },

    deliveries: {
        singular: 'Sendung',
        plural: 'Sendungen',
        state: 'Status',
        tenant: 'Mandant',
        parcelNumber: 'Sendungsnummer',
        created: 'Angelegt',
        ended: 'Beendet',
        stored: 'Eingelagert',
        pickedUp: 'Abgeholt',
        stateEnum: {
            Created: 'Angelegt',
            Stored: 'Eingelagert',
            PickedUp: 'Abgeholt',
            Canceled: 'Abgebrochen',
            Retrieved: 'Entnommen'
        },
        service: 'Service',
        serviceEnum: {
            Outbound: 'Ausgehend',
            Inbound: 'Eingehend'
        },
        cubeBox: 'Cube / Fach',
        accessCodes: 'Zugriffscodes',
        signature: 'Unterschrift',
        sender: {
            name: 'Name',
            email: 'E-Mail',
            phone: 'Phone'
        },
        recipientPickupCode: 'Abholcode Empfänger',
        carrierDeliveryCode: 'Einlagerungscode KEP',
        carrierRetrievalCode: 'Entnahmecode KEP',
        journal: 'Verlauf',
        noDeliveriesFound: 'Keine Sendungen gefunden',
        maxStorageDays: 'Maximale Lagertage überschritten',
        maxStorageDaysNotified: 'Die Lieferung überschreitet die maximal zulässigen Lagertage.',
        criticalStorageDays: 'Kritische Lagertage überschritten',
        criticalStorageDaysNotified: 'Lieferung überschreitet kritische Lagertage. Der Mieter wurde benachrichtigt.',
        disabilityAccess: 'Behindertengerecht',
        parcelNumberHelperText: 'DPD Paketnummern sind 14 Zeichen lang',
        create: {
            title: 'Sendung erstellen',
            selectCube: 'Cube auswählen',
            selectCubeFirst: 'Bitte wählen Sie zuerst einen Würfel',
            selectBox: 'Fach auswählen'
        },
        cancel: {
            title: 'Sendung stornieren',
            message: 'Wollen Sie die Sendung bei A1 wirklich stornieren?'
        },
        delete: {
            title: 'Sendung löschen',
            message: 'Wollen Sie wirklich diese Sendung läschen?'
        },
        markMaxStorageDays: {
            title: 'Maximale Lagerdauer markieren',
            message: 'Warnung: Diese Aktion benachrichtigt den Spediteur und entzieht dem Empfänger den Zugang zur Abholung.'
        },
        notFound: (deliveryUuid: string) => `Sending mit der ${deliveryUuid} kann nicht gefunden werden`,
        eventQueue: 'Event Queue',
        noEvents: (carrierId: string) => `Es wurden noch keine Events an den KEP Dienstleister ${carrierId} übermittelt.`,
        events: {
            executionState: 'Ausführung',
            deliveryState: 'Status',
            deliveryAction: 'Aktion',
            createdAt: 'Angelegt',
            retryAt: 'Wiederholung',
            deliveredAt: 'Zugestellt',
            expiredAt: 'Abgelaufen',
            failures: 'Versuche',
            scannedBarcode: 'Gescannter Bar-Code',
            code: 'Verwendeter Code',
            signature: 'Unterschrift',
            name: 'Absender',
            email: 'E-Mail',
            phone: 'Telefon',
            response: 'Service-Antwort',
            retry: 'Wiederholen',
            retrySuccess: 'Ereignis wird in wenigen Sekunden erneut übertragen.'
        },

        reservation: {
            title: 'Reservierung',
            start: 'Von',
            end: 'Bis'
        },
        notes: 'Notizen',
        filter: {
            fulltext: 'Volltextsuche'
        },
        mockEvents: {
            title: 'Event simulieren'
        },
        callback: {
            title: 'Callback',
            url: 'URL',
            method: 'Method'
        },
        foreignId: 'Foreign-Id'
    },

    tenants: {
        singular: 'Mandant',
        plural: 'Mandanten',
        select: 'Bitte wählen Sie einen Mandanten aus.',
        noTenant: {
            title: 'Kein Mandant gefunden',
            info: 'Ihr Benutzer ist keinem Mandanten zugeordnet, bitte kontaktieren Sie Ihren Helpdesk.'
        }
    },

    users: {
        singular: 'Benutzer',
        plural: 'Benutzer',
        noUsers: 'Keine Benutzer gefunden',
        noCostCenters: 'Keine Kostenstellen gefunden',

        apiToken: 'API-Token',

        id: 'ID',
        firstName: 'Vorname',
        lastName: 'Nachname',
        name: 'Name',
        email: 'E-Mail',
        language: 'Sprache',
        receiveEmails: 'Benachrichtigungs-E-Mails erhalten',
        accessCode: 'Zugangscode',
        state: 'Status',

        role: 'Benutzer-Rolle',
        selectRole: 'Benutzer-Rolle auswählen',

        costCenters: 'Kostenstellen',
        selectCostCenter: 'Kostenstellen auswählen',
        costCenterSelectCount: 'Kostenstellen ausgewählt',

        data: 'Benutzerdaten',
        changePassword: 'Passwort ändern',

        requiredApproval: 'Ihr Konto wird gerade überprüft. Bitte haben Sie etwas Geduld, einer der Administratoren wird Ihre Daten überprüfen und Sie benachrichtigen, sobald Ihr Konto aktiviert ist.',

        create: {
            title: 'Benutzer erstellen'
        },
        edit: {
            title: 'Benutzer bearbeiten'
        },
        delete: {
            title: 'Benutzer löschen',
            prompt: 'Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?',
            hint: 'Diese Aktion kann nicht rückgängig gemacht werden.'
        },
        approval: {
            title: 'Benutzer freigeben',
            prompt: 'Sind Sie sicher, dass Sie diesen Benutzer freigeben möchten?',
        },
        actions: {
            signup: 'Registrierung',
            add: 'Benutzer hinzufügen'
        },
        roles: {
            TenantAdmin: 'Administrator',
            TenantUser: 'Benutzer',
            Borrower: 'Entlehner'
        },
        signupInformation: {
            title: 'Sign-Up Information',
            notConfigured: {
                title: 'Nicht konfiguriert',
                info: 'Ihr System ist nicht für die Registrierung von Entlehnern konfiguriert.',
                goSettings: 'Einstellungen'
            },
            infoDomains: 'Entlehner können sich unter folgenden URLs registrieren.',
            infoEmails: 'Entlehner müssen eine der folgenden E-Mail Domains nutzen um sich zu registrieren.'
        }
    },

    carrierTokens: {
        singular: 'Carrier Token',
        plural: 'Carrier Tokens',
        noTokens: 'Kein carrier token gefunden',
        carrierId: 'Carrier Id',
        apiToken: 'API-Token',
        apiTokenHint: 'Dieses Token wird nur einmal angezeigt. Bitte stellen Sie sicher, dass Sie es auf Ihrem Gerät gespeichert haben, bevor Sie dieses Fenster schließen.',
        create: {
            title: 'Carrier token erstellen',
            result: 'Carrier token erstellt'
        }
    },

    delivery: 'Sendung',
    foreignId: 'Schlüssel Fremdsystem',
    recipients: 'Empfänger',
    createDate: 'Anlagedatum',
    locker: 'Fach',
    state: 'Status',
    filterNoCriteria: 'Kein Filter gesetzt, klicken Sie hier um einen Filter zu setzen',
    filter: 'Filtereinstellungen',
    filterCubes: 'Standorte',
    filterStates: 'Status',
    fulltextSearch: 'Volltextsuche',
    periodUnit: 'Zeitraum Einheit',
    periodUnitHours: 'Stunden',
    periodUnitDays: 'Tage',
    periodUnitWeeks: 'Wochen',
    calculationCase: 'Berechnungsfall',
    calculationCasePerUsage: 'Pro Rental',
    calculationCasePerHour: 'Pro Stunde',
    calculationCasePerDay: 'Pro Tag',
    amount: {
        net: 'Netto',
        gross: 'Brutto',
        tax: 'Steuer',
        taxRate: 'Steuersatz',
    },

    settings: {
        title: 'Einstellungen',
        accessCode: 'Zugangscode',
        refreshAccessCode: 'Zugangscode aktualisieren',
        changePassword: 'Passwort ändern',
        password: 'Passwort',
        confirmPassword: 'Passwort bestätigen',
        passwordChanged: 'Ihr Passwort wurde geändert.',
        invalidPassword: 'Passwörter stimmen nicht überein oder they are too weak.',

        tenantTitle: "Mandat-Einstellungen",
    },

    signup: {
        title: 'Anmelden',
        email: 'E-Mail Adresse',
        password: 'Passwort',
        confirmPassword: 'Passwort bestätigen',
        termAgreementHint: 'Ich habe die VARIOCUBE Rental Locker Nutzungsbedingungen gelesen und stimme diesen zu.',
        passwordPolicyError: 'Das Passwort muss aus mindestens 8 Buchstaben bestehen und Klein- und Großbuchstaben, Zahlen und Sonderzeichen enthalten.',
        passwordNotMatch: 'Passwörter stimmen nicht überein.',
        success: {
            requireApproval: 'Vielen Dank für Ihre Registrierung. Bitte haben Sie etwas Geduld, einer der Administratoren wird Ihre Daten überprüfen und Sie benachrichtigen, sobald Ihr Konto aktiviert ist.',
            automaticallyApproval: 'Vielen Dank für Ihre Registrierung. Sie werden in Kürze zum Login weitergeleitet...'
        }
    },

    errorDialogTitle: "Etwas ist schief gelaufen",
    errorDialogMessage: "Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut. Wenn dieses Problem weiterhin besteht, wenden Sie sich bitte an unseren Support.",
};



const i18n = {
    en: en,
    de: de,
    default: en
};

export const messages = new I18nResolver(i18n).translation;

export function lookupByString(baseObject: any, key: string): string {
    try {
        const resolved = key as keyof typeof baseObject;
        const msg = baseObject[resolved] as string;
        if(msg && typeof msg == "string") {
            return msg;
        }
        console.log(`Key: ${key} did not resolve to a string.`)
    }catch(e) { }
    return key;
}
