import {Api} from "../Api";
import {Delivery, DeliveryCreateRequest, DeliveryState} from "./types";
import {luxonDate, luxonDateOrUndefined} from "../tools";
import {Page, Paging} from "@variocube/app-ui";

export function listDeliveries(paging: Paging, fulltext?: string) {
    const filterParams = fulltext ? 'fulltext=' + fulltext : undefined;
    return Api.GET<Page<Delivery>>('deliveries' + paging.toQueryString(filterParams))
        .then(page => ({
            ...page,
            content: page.content.map(mapResponse)
        }));
}

export function createDelivery(request: DeliveryCreateRequest) {
    return Api.POST<Delivery>(`deliveries`, request)
        .then(mapResponse);
}

export function getDelivery(id: string) {
    return Api.GET<Delivery>(`deliveries/${id}`)
        .then(mapResponse);
}

export function markDeliveryMaxStorageDays(id: string) {
    return Api.PUT<void>(`deliveries/${id}/mark-max-storage-days`, {});
}

export function cancelDelivery(id: string) {
    return Api.PUT<Delivery>(`deliveries/${id}/cancel`, {});
}

export function deleteDelivery(id: string) {
    return Api.DELETE<void>(`deliveries/${id}`);
}

export function addDeliveryNotes(id: string, notes: string) {
    return Api.POST<Delivery>(`deliveries/${id}/notes`, { notes })
        .then(mapResponse);
}

export function addDeliveryMockEvent(deliveryUuid: string, transitionTo: DeliveryState) {
    const request = { transitionTo };
    return Api.POST(`deliveries/${deliveryUuid}/mock-event`, request);
}

const mapResponse = (d: Delivery): Delivery => ({
    ...d,
    createdAt: luxonDate(d.createdAt),
    endedAt: luxonDateOrUndefined(d.endedAt),
    reservationFrom: luxonDateOrUndefined(d.reservationFrom),
    reservationUntil: luxonDateOrUndefined(d.reservationUntil),
    storedAt: luxonDateOrUndefined(d.storedAt),
    pickedUpAt: luxonDateOrUndefined(d.pickedUpAt),
    retrievedAt: luxonDateOrUndefined(d.retrievedAt),
    cancelledAt: luxonDateOrUndefined(d.cancelledAt),
    notifiedMaxStorageDays: luxonDateOrUndefined(d.notifiedMaxStorageDays),
    notifiedCriticalStorageDays: luxonDateOrUndefined(d.notifiedCriticalStorageDays)
});