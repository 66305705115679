import {createElement, Fragment, useEffect, useMemo, useState} from "react";
import {RouteComponentProps} from "react-router";
import {AvailabilityWrapper, Cube} from "../../data/types";
import {ApiError} from "../../Api";
import {listCubes} from "../../data/cubes";
import {createDelivery} from "../../data/deliveries";
import {
    Box,
    Breadcrumbs,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography
} from "@material-ui/core";
import {Crumb} from "../../components/Crumb";
import {messages} from "../../i18n";
import {gs} from "../../theme";
import {Checkbox, TextInput } from "@variocube/app-ui";

export const DeliveryCreate = ({history}: RouteComponentProps) => {
    const [cubes, setCubes] = useState<Cube[]>([]);
    const [boxTypes, setBoxTypes] = useState<string[]>();
    const [cubeId, setCubeId] = useState('');
    const [boxType, setBoxType] = useState('');
    const [parcelIds, setParcelIds] = useState('');
    const [disabilityAccess, setDisabilityAccess] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState<ApiError>();

    useEffect(() => {
        listCubes()
            .then(p => setCubes(p.content))
            .catch(setError);
    }, []);

    const handleCubeChanged = (cubeId: string) => {
        console.log(`handleCubeChanged, setting cube to ${cubeId}`);
        if (cubeId && cubes) {
            const cube = cubes.filter(c => c.id == cubeId).pop();
            if (cube) {
                const availability = new AvailabilityWrapper(cube.availability);
                setCubeId(cubeId);
                setBoxTypes(availability.boxTypes);
                return;
            }
        }
    };

    const create = () => {
        setProcessing(true);
        createDelivery({
            cubeId: cubeId,
            boxType: boxType,
            parcelIds: parcelIds.split(',').map(id => id.trim()),
            disabilityAccess: disabilityAccess
        })
            .then(d => history.push(`/deliveries/${d.id}`))
            .catch(setError);
    };

    const canCreate = useMemo(() => {
        return cubeId && boxType && parcelIds;
    }, [cubeId, boxType, parcelIds]);

    return (
        <Fragment>
            <Box my={1}>
                <Breadcrumbs>
                    <Crumb label={messages.deliveries.plural} route={'/deliveries'} />
                    <Crumb label={messages.deliveries.create.title} />
                </Breadcrumbs>
            </Box>
            <Grid container spacing={gs} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{flexGrow: 1}}>
                    <Typography variant="h4" color="secondary" gutterBottom>{messages.deliveries.create.title}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <Paper>
                        <Box p={3}>
                            <Grid container spacing={gs}>
                                <Grid item md={9} sm={12}>
                                    <FormControl variant="outlined" fullWidth required>
                                        <InputLabel>{messages.cubes.singular}</InputLabel>
                                        <Select name="cubeId"
                                                value={cubeId}
                                                onChange={(e) => handleCubeChanged(e.target.value as string)}
                                                label={messages.cubes.singular}
                                        >
                                            <MenuItem value={''}>{messages.deliveries.create.selectCube}</MenuItem>
                                            {cubes.map(c => <MenuItem key={c.id} value={c.id}>{c.description}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={3} sm={12}>
                                    <FormControl variant="outlined" fullWidth disabled={!boxTypes} required>
                                        <InputLabel>{cubeId ? messages.boxes.singular : messages.deliveries.create.selectCubeFirst}</InputLabel>
                                        <Select name="boxType"
                                                value={boxType}
                                                onChange={(e) => setBoxType(e.target.value as string)}
                                                label={cubeId ? messages.boxes.singular : messages.deliveries.create.selectCubeFirst}
                                        >
                                            <MenuItem value={''}>{messages.deliveries.create.selectBox}</MenuItem>
                                            {boxTypes && boxTypes.map(b => <MenuItem key={b} value={b}>{b}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Box my={1}/>
                            <Grid container spacing={gs}>
                                <Grid item md={9}>
                                    <TextInput label={messages.deliveries.parcelNumber}
                                               value={parcelIds}
                                               onChange={setParcelIds} />
                                </Grid>
                                <Grid item md={3}>
                                    <Checkbox label={messages.deliveries.disabilityAccess}
                                              value={disabilityAccess}
                                              onChange={setDisabilityAccess} />
                                </Grid>
                            </Grid>
                            <Box my={3}/>
                            <Box display="flex" justifyContent="flex-end">
                                <Button variant="contained" color="primary"
                                        disabled={processing || !canCreate}
                                        onClick={create}>{messages.actions.create}</Button>
                                <Box mx={1} />
                                <Button variant="contained" onClick={() =>  history.push("/deliveries")}>{messages.actions.cancel}</Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    )
}