import {DateTime} from "luxon";

export function luxonDate(date: any): DateTime {
    if(!date) {
        return DateTime.local();
    } else if(typeof date === "string") {
        return DateTime.fromISO(date);
    } else if(date instanceof DateTime) {
        return date;
    } else if(typeof date === "number") {
        return DateTime.fromMillis(date);
    }else if(date instanceof Date) {
        return DateTime.fromJSDate(date);
    }
    throw Error('Date was in a not supported type')
}

export function luxonDateOrUndefined(date: any): DateTime | undefined {
    if(!date) {
        return undefined;
    }
    return luxonDate(date);
}

export function pad(num: number, size: number) {
    var s = "000000000" + num;
    return s.substr(s.length-size);
}

export function initials(name: string, length?: number) {
    if(!length) {
        length = 2;
    }
    return name.toUpperCase().split(" ").map((n) => n[0]).join("").substr(0, length);
}

export function maxLen(value: string, maxLen: number, end?: string): string {
    if(!value) {
        return '';
    }
    if(value.length <= maxLen) {
        return value;
    }
    if(!end) { end = ' ...' ; }
    const keep = value.substr(0, maxLen - end.length);
    return `${keep}${end}`;
}

export function enumKeys<E>(e: E): (keyof E)[] {
    return Object.keys(e) as (keyof E)[];
}
