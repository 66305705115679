import {RouteComponentProps} from "react-router";
import React, {createElement, Fragment, useEffect, useState} from "react";
import {User} from "../../data/types";
import {ApiError} from "../../Api";
import {getUser} from "../../data/users";
import {Box, Breadcrumbs, Grid, Paper, Typography} from "@material-ui/core";
import {Loading} from "../../components/Loading";
import {Crumb} from "../../components/Crumb";
import {messages} from "../../i18n";
import {gs} from "../../theme";
import {LabeledData} from "../../components/LabeledData";

export const UserDetails = ({match, history}: RouteComponentProps<{ id: string }>) => {
    const [user, setUser] = useState<User>();
    const [error, setError] = useState<ApiError>();

    useEffect(() => {
        getUser(match.params.id)
            .then(setUser)
            .catch(setError);
    }, [setUser]);

    return (
        <Fragment>
            {!user && (
                <Box p={5}>
                    <Loading />
                </Box>
            )}
            {user && (
                <Fragment>
                    <Box my={1}>
                        <Breadcrumbs>
                            <Crumb label={messages.users.plural} route="/users" />
                            <Crumb label={messages.actions.view} />
                        </Breadcrumbs>
                    </Box>
                    <Grid container spacing={gs} style={{ justifyContent: 'flex-end' }}>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h4" gutterBottom>{user.email}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={gs}>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={2}>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <LabeledData label={messages.common.email}>
                                                {user.email}
                                            </LabeledData>
                                        </Grid>
                                        <Grid item>
                                            <LabeledData label={messages.users.role}>
                                                {user.role}
                                            </LabeledData>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}>
                                            <LabeledData label={messages.users.id}>
                                                {user.username}
                                            </LabeledData>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Fragment>
            )}
        </Fragment>
    )
}