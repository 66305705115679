import {createElement} from "react";
import {Chip} from "@material-ui/core";
import {lookupByString, messages} from "../../i18n";
import {UserRole} from "../../data/types";

interface UserRoleDisplayProps {
    role: UserRole;
    size?: 'small'|'medium';
}

export function UserRoleDisplayComponent(props: UserRoleDisplayProps) {
    const label = lookupByString(messages.users.roles, props.role);
    return (<Chip size={props.size||'small'} label={label} />);
}
