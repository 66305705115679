import {DateTime} from "luxon";

export enum DeliveryService {
    Outbound = "Outbound",
    Inbound = "Inbound"
}

export enum DeliveryState {
    Created = "Created",
    Stored = "Stored",
    PickedUp = "PickedUp",
    Retrieved = "Retrieved",
    Canceled = "Canceled",
}

export interface Callback {
    method: string;
    url: string;
}

export interface Delivery {
    id: string;
    tenantId: string;
    foreignId?: string;
    reservationId: string;
    parcelIds: string[];
    service: DeliveryService;
    state: DeliveryState;
    cubeId: string;
    boxNumber: string;
    callback?: Callback;
    boxType?: string;
    carrierDeliveryCode: string;
    carrierRetrievalCode: string;
    recipientPickupCode: string;

    signature?: string;

    createdAt: DateTime;
    lastUpdateAt?: DateTime;
    endedAt?: DateTime;

    reservationFrom?: DateTime;
    reservationUntil?: DateTime;

    storedAt?: DateTime;
    pickedUpAt?: DateTime;
    retrievedAt?: DateTime;
    cancelledAt?: DateTime;

    notifiedMaxStorageDays?: DateTime;
    notifiedCriticalStorageDays?: DateTime;

    disabilityAccess: boolean;

    notes?: string;
}

export interface DeliveryCreateRequest {
    cubeId: string;
    boxType: string;
    parcelIds: string[];
    disabilityAccess: boolean;
}

export interface DeliveryFilter {
    fulltext?: string;
}

export interface Availability {
    total: number;
    availability: { [index: string]: number };
}

export class AvailabilityWrapper {

    readonly availability: Availability;

    constructor(availability: Availability) {
        this.availability = availability;
    }

    get boxTypes(): string[] {
        const sizes: string[] = [];
        const keys = Object.keys(this.availability.availability);
        for(let key of keys) {
            const a = this.availability.availability[key];
            if(a) {
                sizes.push(key);
            }
        }
        return sizes;
    }

}

export enum AppLockStatus {
    Open = "Open",
    Closed = "Closed",
    Breakin = "Breakin",
    Blocked = "Blocked",
}

export interface Occupancy {
    uuid: string;
    cubeId: string;
    boxNumber: string;
    lock: string;
    accessCode: string;
    accessKeys: string[];
    created: DateTime;
    updated: DateTime;
    ended?: DateTime;
    deliveryId: string;
    deliveryUuid: string;
}

export interface CarrierAccessCode {
    accessCode: string;
    pin: string;
}

export interface AccessCodes {
    carrier: {[carrierId: string]: CarrierAccessCode};
    readOnly: string;
    openNonOccupied: string;
    fullAccess: string;
}

export interface Address {
    countryCode: string;
    postalCode: string;
    street: string;
    city: string;
    houseNumber: string;
}

export interface Cube {
    id: string;
    description: string;
    connected: boolean;
    countryCode: string;
    postalCode: string;
    city: string;
    street: string;
    houseNumber: string;
    latitude: number;
    longitude: number;
    imageUrl: string;
    availability: Availability;
}

export interface CubeDownTime {
    key: string;
    startDate: DateTime;
    endDate: DateTime;
    comment: string;
}

export interface Box {
    number: string;
    description: string;
    lock: string;
    secondaryLock?: string;
    types: string[];
    lockStatus: AppLockStatus;
    secondaryLockStatus?: AppLockStatus;
    disabled: boolean;
    occupancies: Occupancy[];
}

export interface SetBoxStateRequest {
    disabled: boolean;
}

export enum UserRole {
    Admin = 'Admin',
    User = 'User'
}

export interface User {
    uuid: string;
    username: string;
    email: string;
    role: UserRole;
    active: boolean;
    carrierId: string;
    root: boolean;
    apiToken?: string;
}

export interface UserCreateRequest {
    email: string;
    role: UserRole;
}

export enum QueueStatus {
    Created = "Created",
    Failed = "Failed",
    Succeeded = "Succeeded"

}

export interface QueueItem {
    uuid: string;
    version: number;
    deliveryId: string;
    status: QueueStatus;
    state: string;
    actionType: string;
    scannedBarcode: string;
    signature: string;
    code: string;
    name: string;
    email: string;
    phone: string;
    response: string;
    failureCount: number;
    created: DateTime;
    retry: DateTime;
    delivered: DateTime;
    expired: DateTime;
}

export interface CarrierToken {
    uuid: string;
    carrierId: string;
}

export interface CarrierTokenCreateResponse {
    uuid: string;
    carrierId: string;
    rawToken: string;
}
