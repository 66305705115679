import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {Box, Breadcrumbs, Grid, Paper, TableCell, TableRow, Typography} from "@material-ui/core";
import {Crumb} from "../../components/Crumb";
import {messages} from "../../i18n";
import {gs} from "../../theme";
import {ColumnType, ContentTable, Page} from "@variocube/app-ui";
import {Cube} from "../../data/types";
import {ApiError} from "../../Api";
import {listCubes} from "../../data/cubes";
import {cubesPaging} from "../../data/pagings";
import {CubeConnectedComponent} from "./CubeConnectedComponent";
import {CubeAvailabilityComponent} from "./CubeAvailabilityComponent";

const baseColumns: ColumnType = {
    'description': { show: true, name: messages.cubes.singular, unsortable: true },
    'cubeId': { show: true, name: messages.cubes.cubeId, unsortable: true },
    'connected': { show: true, name: messages.cubes.connected, unsortable: true },
    'utilization': { show: true, name: messages.cubes.availability.label }
}

export const CubeList = ({history}: RouteComponentProps) => {
    const [page, setPage] = useState<Page<Cube>>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<ApiError>();
    const [columns, setColumns] = useState<ColumnType>(baseColumns)

    const load = useCallback(async () => {
        setLoading(true);
        try {
            const page = await listCubes(cubesPaging);
            setPage(page);
        } catch (error) {
            setError(error);
        }
        setLoading(false);
    }, [setPage, setError, setLoading]);

    useEffect(() => {
        load().then();
    }, [])

    const showCell = (column: keyof typeof columns) => columns[column] && columns[column].show;

    return (
        <Fragment>
            <Box my={1}>
                <Breadcrumbs>
                    <Crumb label={messages.cubes.plural} />
                </Breadcrumbs>
            </Box>
            <Grid container spacing={gs} style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{flexGrow: 1}}>
                    <Typography variant="h4" gutterBottom>{messages.deliveries.plural}</Typography>
                </Grid>
                <Grid item>
                    {/*    TODO: TABLE SEACH INPUT */}
                </Grid>
            </Grid>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <Paper>
                        {page && (
                            <ContentTable page={page} paging={cubesPaging}
                                          inProgress={loading}
                                          columns={columns}
                                          onPagingChange={() => load()}
                                          onColumnsChange={setColumns}
                                          renderTableBody={(
                                              <Fragment>
                                                  {page.content.map(c => (
                                                      <TableRow key={c.id} onClick={() => history.push(`/cubes/${c.id}`)}>
                                                          {showCell('description') && <TableCell>{c.description}</TableCell>}
                                                          {showCell('cubeId') && <TableCell>{c.id}</TableCell>}
                                                          {showCell('connected') && <TableCell><CubeConnectedComponent connected={c.connected} /></TableCell>}
                                                          {showCell('utilization') && <TableCell><CubeAvailabilityComponent availability={c.availability} /></TableCell>}
                                                      </TableRow>
                                                  ))}
                                              </Fragment>
                                          )}
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    )
}