import {User, UserRole} from "../data/types";
import {me} from "../data/users";

class UserStore {

    private static readonly USER_KEY = 'user';
    private static readonly TENANT_KEY = 'tenant';

    setUser(user: User) {
        localStorage.setItem(UserStore.USER_KEY, JSON.stringify(user));
    }

    get user() {
        const data = localStorage.getItem(UserStore.USER_KEY);
        return data ? JSON.parse(data) as User : undefined;
    }

    get root() {
        return this.user != undefined && this.user.root;
    }

    get admin() {
        return this.hasRole(UserRole.Admin) || this.root;
    }

    hasRole(userRole: UserRole): boolean {
        if(this.user) {
            return this.user.role === userRole;
        }
        return false;
    }

    load(): Promise<User> {
        return me()
            .then((user) => {
                this.setUser(user)
                return user;
            });
    }
}

export const userStore = new UserStore();