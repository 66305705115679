import {createElement} from "react";
import {Grid, Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {messages} from "../../i18n";
import {Availability} from "../../data/types";
import {gs} from "../../theme";

interface CubeAvailabilityComponentProps {
    availability: Availability;
}

export function CubeAvailabilityComponent(props: CubeAvailabilityComponentProps) {
    const {availability} = props;
    const classes = useStyles();
    const total = availability.total || 1;
    const small = (availability.availability['S'] || 0) / total;
    const medium = (availability.availability['M'] || 0) / total;
    const large = (availability.availability['L'] || 0) / total;
    const xlarge = (availability.availability['XL'] || 0) / total;
    return (
        <Tooltip title={<CubeAvailabilityTooltipContent availability={availability} />}>
            <div className={classes.utilisationContainer}>
                <div style={{width: `${small}%`}} className={classes.small}/>
                <div style={{width: `${medium}%`}} className={classes.medium}/>
                <div style={{width: `${large}%`}} className={classes.large}/>
                <div style={{width: `${xlarge}%`}} className={classes.xlarge}/>
            </div>
        </Tooltip>
    );
}

function CubeAvailabilityTooltipContent(props: CubeAvailabilityComponentProps) {
    const {total, availability} = props.availability;
    const small = availability['S'];
    const medium = availability['M'];
    const large = availability['L'];
    const xlarge = availability['XL'];
    return(
        <Grid container spacing={gs}>
            <Grid item>
                <label>{messages.cubes.availability.total}:</label> {total}
            </Grid>
            {small &&
            <Grid item>
                <IndicatorBox color="#8C8838" />
                <label>{messages.cubes.availability.small}:</label> {small}
            </Grid>
            }
            {medium &&
            <Grid item>
                <IndicatorBox color="#8C8838" />
                <label>{messages.cubes.availability.medium}:</label> {medium}
            </Grid>
            }
            {large &&
            <Grid item>
                <IndicatorBox color="#8C8838" />
                <label>{messages.cubes.availability.large}:</label> {large}
            </Grid>
            }
            {xlarge &&
            <Grid item>
                <IndicatorBox color="#8C8838" />
                <label>{messages.cubes.availability.xlarge}:</label> {xlarge}
            </Grid>
            }
        </Grid>
    );
}

interface IndicatorBoxProps {
    color: string;
}

function IndicatorBox(props: IndicatorBoxProps) {
    const classes = useStyles();
    return (<div className={classes.indicatorBox} style={{backgroundColor: props.color}}/>);
}

const useStyles = makeStyles({
    utilisationContainer: {
        width: '100%',
        display: 'flex',
        height: '1rem',
        overflow: 'hidden',
        fontSize: '0.75rem',
        backgroundColor: '#e9ecef',
        borderColor: 'e9ecef'
    },
    small: {
        justifyContent: 'center',
        textAlign: 'center',
        color: '#fff',
        backgroundColor: '#8C8838',
        whiteSpace: 'nowrap',
    },
    medium: {
        justifyContent: 'center',
        textAlign: 'center',
        color: '#fff',
        backgroundColor: '#f44336',
        whiteSpace: 'nowrap',
    },
    large: {
        justifyContent: 'center',
        textAlign: 'center',
        color: '#fff',
        backgroundColor: '#f44336',
        whiteSpace: 'nowrap',
    },
    xlarge: {
        justifyContent: 'center',
        textAlign: 'center',
        color: '#fff',
        backgroundColor: '#f44336',
        whiteSpace: 'nowrap',
    },
    indicatorBox: {
        display: 'inline-block',
        width: '1em', height: '1em',
        marginTop: '0.3em',
        marginRight: '1em'
    }
});
