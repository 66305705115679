import {Page, Paging} from "@variocube/app-ui";
import {Api} from "../Api";
import {Cube} from "./types";

export function listCubes(paging?: Paging) {
    return Api.GET<Page<Cube>>('cubes' + (paging ? paging.toQueryString() : ''));
}

export function getCube(cubeId: string) {
    return Api.GET<Cube>(`cubes/${cubeId}`);
}