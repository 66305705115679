import {createElement } from "react";
import {CircularProgress} from "@material-ui/core";
import {messages} from "../i18n";

export const Loading = (props: { message?: string }) => {
    return (
        <div>
            <SmallLoading />
            <p style={{ textAlign: 'center' }}>{ props.message || messages.loading }</p>
        </div>
    );
};

interface SmallLoading {
    size?: number;
}

export function SmallLoading(props: SmallLoading) {
    const size = props.size || 40;
    return(<CircularProgress size={size} style={{ display: 'block', margin: 'auto' }} />);
}
