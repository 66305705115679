import {Page, Paging} from "@variocube/app-ui";
import {Api} from "../Api";
import {User, UserCreateRequest} from "./types";

export function listUsers(paging: Paging) {
    return Api.GET<Page<User>>('users' + paging.toQueryString());
}

export function createUser(request: UserCreateRequest) {
    return Api.POST<User>(`users`, request);
}

export function me() {
    return Api.GET<User>(`users/current-user`);
}

export function getUser(uuid: string) {
    return Api.GET<User>(`users/${uuid}`);
}

export function deleteUser(uuid: string) {
    return Api.DELETE<void>(`users/${uuid}`);
}