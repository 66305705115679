import {createElement, Fragment, useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {Cube} from "../../data/types";
import {ApiError} from "../../Api";
import {getCube} from "../../data/cubes";
import {Box, Breadcrumbs, Grid, Paper, Typography} from "@material-ui/core";
import {Loading} from "../../components/Loading";
import {Crumb} from "../../components/Crumb";
import {messages} from "../../i18n";
import {gs} from "../../theme";
import {LabeledData} from "../../components/LabeledData";
import {CubeConnectedComponent} from "./CubeConnectedComponent";
import {CubeAvailabilityComponent} from "./CubeAvailabilityComponent";
import {AddressDisplay} from "../../components/AddressDisplay";
import {GoogleMaps} from "../../components/GoogleMaps";

export const CubeDetails = ({match}: RouteComponentProps<{ id: string }>) => {
    const [cube, setCube] = useState<Cube>();
    const [error, setError] = useState<ApiError>();

    useEffect(() => {
        getCube(match.params.id)
            .then(setCube)
            .catch(setError);
    }, []);

    return (
        <Fragment>
            {!cube && (
                <Box p={5}>
                    <Loading />
                </Box>
            )}
            {cube && (
                <Fragment>
                    <Box my={1}>
                        <Breadcrumbs>
                            <Crumb label={messages.cubes.plural} route="/cubes" />
                            <Crumb label={cube.description} />
                        </Breadcrumbs>
                    </Box>
                    <Grid container spacing={gs}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item style={{flexGrow: 1}}>
                                    <Typography variant="h4" color="secondary" gutterBottom>
                                        {`${messages.cubes.singular}: ${cube.id}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={3}>
                                            <Grid container spacing={gs}>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={gs}>
                                                        <Grid item>
                                                            <LabeledData label={messages.cubes.connectionState}>
                                                                <CubeConnectedComponent connected={cube.connected} />
                                                            </LabeledData>
                                                        </Grid>
                                                        <Grid item style={{flexGrow: 1}}>
                                                            <LabeledData label={messages.cubes.availability.label}>
                                                                <CubeAvailabilityComponent availability={cube.availability} />
                                                            </LabeledData>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={gs}>
                                                        <Grid item style={{flexGrow: 1}}>
                                                            <LabeledData label={messages.cubes.address}>
                                                                <AddressDisplay address={cube} />
                                                            </LabeledData>
                                                        </Grid>
                                                        <Grid item>
                                                            <LabeledData label={messages.cubes.location}>
                                                                <GoogleMaps latitude={cube.latitude} longitude={cube.longitude} />
                                                            </LabeledData>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Fragment>
            )}
        </Fragment>
    )
}