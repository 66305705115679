import {createElement} from "react";
import {Grid} from "@material-ui/core";
import {Address} from "../data/types";

interface AddressDisplayProps {
    address: Address;
}

export function AddressDisplay(props: AddressDisplayProps) {
    const {countryCode, postalCode, city, street, houseNumber} = props.address;

    const line1 = `${street} ${houseNumber}`.trim();
    const line2 = `${postalCode} ${city}`.trim();
    const line3 = `${countryCode}`.trim();

    return (
        <Grid container spacing={1}>
            {line1 && <Grid item xs={12}>{line1}</Grid>}
            {line2 && <Grid item xs={12}>{line2}</Grid>}
            {line3 && <Grid item xs={12}>{line3}</Grid>}
        </Grid>
    );
}
