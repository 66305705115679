import React, {createElement, Fragment, useCallback, useEffect, useState} from "react"
import {RouteComponentProps} from "react-router";
import {Delivery, DeliveryState} from "../../data/types";
import {ApiError} from "../../Api";
import {addDeliveryMockEvent, addDeliveryNotes, getDelivery} from "../../data/deliveries";
import {
    Box,
    Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, MenuItem,
    Paper,
    TextField,
    Typography
} from "@material-ui/core";
import {Loading} from "../../components/Loading";
import {Crumb} from "../../components/Crumb";
import {messages} from "../../i18n";
import {gs} from "../../theme";
import {DeliveryActions} from "./DeliveryActions";
import {Alert, AlertTitle} from "@material-ui/lab";
import {LabeledData} from "../../components/LabeledData";
import {DeliveryStateComponent} from "./DeliveryStateComponent";
import {DisabilityAccessComponent} from "./DisabilityAccessComponent";
import {DateComponent} from "../../components/DateComponent";
import {SignatureComponent} from "../../components/SignatureComponent";
import {AddIcon, DoneIcon, SaveAltIcon, CloseIcon} from "../../icons";
import {enumKeys} from "../../tools";

export const DeliveryDetails = ({history, match}: RouteComponentProps<{ id: string }>) => {
    const [delivery, setDelivery] = useState<Delivery>();
    const [notes, setNotes] = useState('');
    const [cancelMode, setCancelMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [markMaxStorageDaysMode, setMarkMaxStorageDaysMode] = useState(false);
    const [mockEventMode, setMockEventMode] = useState<boolean>(false);
    const [mockEvent, setMockEvent] = useState<DeliveryState>(DeliveryState.Created);
    const [error, setError] = useState<ApiError>();

    const load = useCallback(() => {
        getDelivery(match.params.id)
            .then(delivery => {
                setDelivery(delivery);
                setMockEvent(delivery.state);
                setNotes(delivery.notes || '');
            })
            .catch(setError);
    }, [match, setDelivery, setError]);

    useEffect(() => {
        load();
    }, []);

    const toggleCancel = () => {
        setCancelMode(!cancelMode);
    };

    const toggleDelete = () => {
        setDeleteMode(!deleteMode);
    };

    const toggleMarkMaxStorageDays = () => {
        setMarkMaxStorageDaysMode(!markMaxStorageDaysMode);
    };

    const handleSaveNotes = () => {
        if (delivery && notes) {
            addDeliveryNotes(delivery.id, notes)
                .then(setDelivery);
        }
    }

    const handleMockEventChanged = (value: string) => {
        setMockEvent(value as DeliveryState);
    }

    const handleMockEvent = () => {
        if(delivery) {
            addDeliveryMockEvent(delivery.id, mockEvent)
                .catch((e) => setError(e.toString()))
                .finally(() => setMockEventMode(false));
        }
    }

    return (
        <Fragment>
            {!delivery && (
                <Box p={5}>
                    <Loading />
                </Box>
            )}
            {delivery && (
                <Fragment>
                    <Box my={1}>
                        <Breadcrumbs>
                            <Crumb label={messages.deliveries.plural} route="/deliveries" />
                            <Crumb label={delivery.id} />
                        </Breadcrumbs>
                    </Box>
                    <Grid container spacing={gs}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item style={{flexGrow: 1}}>
                                    <Typography variant="h4" color="secondary" gutterBottom>
                                        {`${messages.deliveries.singular}: ${delivery.id}`}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <DeliveryActions onCancelDelivery={toggleCancel}
                                                     onDeleteDelivery={toggleDelete}
                                                     onMarkMaxStorageDays={toggleMarkMaxStorageDays}
                                                     onMockEvents={() => setMockEventMode(true)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={3}>
                                            <Grid container spacing={3}>
                                                <Grid item>
                                                    <LabeledData label={messages.common.state}>
                                                        <DeliveryStateComponent state={delivery.state} size="small" />
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={messages.deliveries.service}>
                                                        {messages.deliveries.services[delivery.service]}
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item style={{flexGrow: 1}}>
                                                    <LabeledData label={messages.deliveries.reservation.title}>
                                                        {delivery.reservationId}
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={messages.deliveries.cubeBox}>
                                                        {delivery.cubeId}
                                                        {delivery.boxNumber && <React.Fragment>
                                                            {` / ${delivery.boxNumber}`}
                                                        </React.Fragment>}
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={messages.deliveries.disabilityAccess}>
                                                        <DisabilityAccessComponent disabilityAccess={delivery.disabilityAccess} size="small"/>
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={messages.common.created}>
                                                        <DateComponent date={delivery.createdAt} />
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={messages.deliveries.ended}>
                                                        {delivery.endedAt ? <DateComponent date={delivery.endedAt} /> : '--'}
                                                    </LabeledData>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Grid container spacing={gs}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">{messages.deliveries.accessCodes}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper>
                                                <Box p={3}>
                                                    <Grid container spacing={1}>
                                                        {delivery.recipientPickupCode &&
                                                        <Grid item xs={12}>
                                                            <LabeledData label={messages.deliveries.recipientPickupCode}>
                                                                <Typography variant="h5">{delivery.recipientPickupCode}</Typography>
                                                            </LabeledData>
                                                        </Grid>
                                                        }
                                                        {delivery.carrierDeliveryCode &&
                                                        <Grid item xs={12}>
                                                            <LabeledData label={messages.deliveries.carrierDeliveryCode}>
                                                                <Typography variant="h5">{delivery.carrierDeliveryCode}</Typography>
                                                            </LabeledData>
                                                        </Grid>
                                                        }
                                                        <Grid item xs={12}>
                                                            <LabeledData label={messages.deliveries.carrierRetrievalCode}>
                                                                <Typography variant="h5">{delivery.carrierRetrievalCode}</Typography>
                                                            </LabeledData>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                        {delivery.signature && (
                                            <Fragment>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5">{messages.deliveries.signature}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Paper>
                                                        <Box p={2}>
                                                            <SignatureComponent signature={delivery.signature} />
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            </Fragment>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Grid container spacing={gs}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">{messages.deliveries.journal}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper>
                                                <List>
                                                    {delivery.createdAt && (
                                                        <ListItem>
                                                            <ListItemIcon><AddIcon/></ListItemIcon>
                                                            <ListItemText primary={messages.deliveries.states.Created} secondary={<DateComponent date={delivery.createdAt} />} />
                                                        </ListItem>
                                                    )}
                                                    {delivery.storedAt && (
                                                        <ListItem>
                                                            <ListItemIcon><SaveAltIcon/></ListItemIcon>
                                                            <ListItemText primary={messages.deliveries.states.Stored} secondary={<DateComponent date={delivery.storedAt} />} />
                                                        </ListItem>
                                                    )}
                                                    {delivery.pickedUpAt && (
                                                        <ListItem>
                                                            <ListItemIcon><DoneIcon/></ListItemIcon>
                                                            <ListItemText primary={messages.deliveries.states.PickedUp} secondary={<DateComponent date={delivery.pickedUpAt} />} />
                                                        </ListItem>
                                                    )}
                                                    {delivery.retrievedAt && (
                                                        <ListItem>
                                                            <ListItemIcon><CloseIcon/></ListItemIcon>
                                                            <ListItemText primary={messages.deliveries.states.Retrieved} secondary={<DateComponent date={delivery.retrievedAt} />} />
                                                        </ListItem>
                                                    )}
                                                    {delivery.cancelledAt && (
                                                        <ListItem>
                                                            <ListItemIcon><CloseIcon/></ListItemIcon>
                                                            <ListItemText primary={messages.deliveries.states.Canceled} secondary={<DateComponent date={delivery.cancelledAt} />} />
                                                        </ListItem>
                                                    )}
                                                </List>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    {(delivery.reservationFrom || delivery.reservationUntil) &&
                                    <Grid container spacing={gs}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">{messages.deliveries.reservation.title}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper>
                                                <Box p={2}>
                                                    <Grid container spacing={gs}>
                                                        {delivery.reservationFrom &&
                                                        <Grid item>
                                                            <LabeledData label={messages.deliveries.reservation.start}>
                                                                <DateComponent date={delivery.reservationFrom} />
                                                            </LabeledData>
                                                        </Grid>
                                                        }
                                                        {delivery.reservationUntil &&
                                                        <Grid item>
                                                            <LabeledData label={messages.deliveries.reservation.end}>
                                                                <DateComponent date={delivery.reservationUntil} />
                                                            </LabeledData>
                                                        </Grid>
                                                        }
                                                    </Grid>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {delivery.notifiedMaxStorageDays && (
                                <Box my={1}>
                                    <Alert severity="warning">
                                        <AlertTitle>{messages.deliveries.maxStorageDays}</AlertTitle>
                                        <DateComponent date={delivery.notifiedMaxStorageDays}/>
                                        <br/>
                                        {messages.deliveries.maxStorageDaysNotified}
                                    </Alert>
                                </Box>
                            )}
                            {delivery.notifiedCriticalStorageDays && (
                                <Box my={1}>
                                    <Alert severity="error">
                                        <AlertTitle>{messages.deliveries.criticalStorageDays}</AlertTitle>
                                        <DateComponent date={delivery.notifiedCriticalStorageDays}/>
                                        <br/>
                                        {messages.deliveries.criticalStorageDaysNotified}
                                    </Alert>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={gs}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{messages.deliveries.notes}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth multiline variant="outlined" value={notes} onChange={(e) => setNotes(e.target.value)} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography align="right" component="div">
                                        <Button variant="contained" color="primary"
                                                disabled={(delivery.notes || '') == notes}
                                                onClick={handleSaveNotes}>{messages.actions.save}</Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {(delivery.callback && delivery.callback.url) &&
                    <Grid item xs={12}>
                        <Grid container spacing={gs}>
                            <Grid item xs={12}>
                                <Typography variant="h5">{messages.deliveries.callback.title}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <Box p={2}>
                                        <Grid container spacing={gs}>
                                            <Grid item>
                                                <LabeledData label={messages.deliveries.callback.url}>
                                                    {delivery.callback.url}
                                                </LabeledData>
                                            </Grid>
                                            <Grid item>
                                                <LabeledData label={messages.deliveries.callback.method}>
                                                    {delivery.callback.method}
                                                </LabeledData>
                                            </Grid>
                                            {delivery.foreignId &&
                                            <Grid item>
                                                <LabeledData label={messages.deliveries.foreignId}>
                                                    {delivery.foreignId}
                                                </LabeledData>
                                            </Grid>
                                            }
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    }
                </Fragment>
            )}


            <Dialog open={mockEventMode} onClose={() => setMockEventMode(false)}>
                <DialogTitle>{messages.deliveries.mockEvents.title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={gs}>
                        <Grid item xs={12}>
                            <TextField variant="outlined" select fullWidth value={mockEvent} onChange={(e) => handleMockEventChanged(e.target.value)}>
                                {enumKeys(DeliveryState).map((key) =>
                                    <MenuItem key={key} value={key}>{key}</MenuItem>
                                )}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setMockEventMode(false)}>{messages.actions.cancel}</Button>
                    <Button color="primary" variant="contained" onClick={handleMockEvent}>{messages.actions.confirm}</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}