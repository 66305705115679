import {Button, ButtonGroup, ClickAwayListener, MenuItem, MenuList, Paper, Popper} from "@material-ui/core";
import {createElement, useRef, useState} from "react";
import {messages} from "../../i18n";
import {ArrowDropDownIcon} from "../../icons";

type DeliveryActionsProps = {
    onCancelDelivery: () => void;
    onDeleteDelivery: () => void;
    onMarkMaxStorageDays: () => void;
    onMockEvents: () => void;
}

export const DeliveryActions = (props: DeliveryActionsProps) => {
    const [popperOpen, setPopperOpen] = useState<boolean>(false);
    let anchorRef = useRef<HTMLButtonElement>(null);

    const togglePopper = () => {
        setPopperOpen(!popperOpen);
    };

    return (
        <ButtonGroup variant="outlined">
            <Button onClick={props.onMarkMaxStorageDays}>{messages.deliveries.markMaxStorageDays.title}</Button>
            <Button onClick={togglePopper} ref={anchorRef}><ArrowDropDownIcon/></Button>
            {anchorRef && (
                <Popper open={popperOpen} anchorEl={anchorRef.current} transition disablePortal style={{ zIndex: 999 }}>
                    <Paper>
                        <ClickAwayListener onClickAway={togglePopper}>
                            <MenuList>
                                <MenuItem key="cancelDelivery" onClick={props.onCancelDelivery}>{messages.deliveries.cancel.title}</MenuItem>
                                <MenuItem key="deleteDelivery" onClick={props.onDeleteDelivery}>{messages.deliveries.delete.title}</MenuItem>
                                <MenuItem key="mockEvents" onClick={props.onMockEvents}>{messages.deliveries.mockEvents.title}</MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            )}
        </ButtonGroup>
    )
};
